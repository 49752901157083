<template>
  <b-row>
    <b-col md="2">
      <b-card id="location-filters" v-if="!isLocalLayout()">
        <b-row>
          <b-col
            class="align-items-center justify-content-center d-flex form-group"
            xs="3"
            v-for="filter of filters"
            v-bind:key="filter.type"
          >
            <img
              :title="getTitle(filter.type)"
              class="imgIcon"
              :class="{
                isDisabled: !selectedFilters[filter.type - 1],
                pointer: true,
              }"
              @click="changeFilter(filter.type)"
              :src="filter.image"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-form-group
        :label="$t('map.search-locator')"
        label-for="name"
        class="mb-2"
      >
        <b-form-input
          id="name"
          :placeholder="$t('map.search-location')"
          v-model="serachString"
        />
      </b-form-group>
      <b-row class="mr-0 ml-0 mb-1">
        <b-col class="text-center p-0" :cols="isRadarModuleActivated ? 3 : 4">
          <button
            :class="{ opacity: !deviceStates[0] }"
            @click="changeDeviceStateFilter(0)"
            class="roundbutton button-green"
          >
            {{ numberOfDevices(1) }}
          </button>
        </b-col>
        <b-col class="text-center p-0" :cols="isRadarModuleActivated ? 3 : 4">
          <button
            :class="{ opacity: !deviceStates[1] }"
            @click="changeDeviceStateFilter(1)"
            class="roundbutton button-orange"
          >
            {{ numberOfDevices(2) }}
          </button>
        </b-col>
        <b-col class="text-center p-0" :cols="isRadarModuleActivated ? 3 : 4">
          <button
            :class="{ opacity: !deviceStates[2] }"
            @click="changeDeviceStateFilter(2)"
            class="roundbutton button-red"
          >
            {{ numberOfDevices(3) }}
          </button>
        </b-col>
        <b-col
          v-if="isRadarModuleActivated"
          class="text-center p-0"
          :cols="isRadarModuleActivated ? 3 : 4"
        >
          <button
            :class="{ opacity: !deviceStates[4] }"
            @click="changeDeviceStateFilter(4)"
            class="roundbutton button-blue"
          >
            {{ numberOfDevices(5) }}
          </button>
        </b-col>
        <!--<b-col class="text-center p-0" cols="3">
          <button
            :class="{ opacity: !deviceStates[3] }"
            @click="changeDeviceStateFilter(3)"
            class="roundbutton button-yellow"
          ></button>
        </b-col>-->
      </b-row>
      <app-collapse
        v-if="!isLocalLayout()"
        :class="{ isDisabled: !selectedFilters[0] }"
        class="mb-1"
      >
        <app-collapse-item :title="$t('Speed monitor')">
          <template slot="header">
            <div class="d-flex justify-content-between w-100">
              <span class="font-weight-bold ml-1">{{
                $t('Speed monitor')
              }}</span>
              <div style="float: right">
                {{ locations(1).length }} / {{ allLocationsCount(1) }}
              </div>
            </div>
          </template>
          <location-list
            @zoomIn="zoomIn"
            :locations="locations(1)"
            :allLocationsCount="allLocationsCount(1)"
          ></location-list>
        </app-collapse-item>
      </app-collapse>
      <app-collapse
        v-if="!isLocalLayout()"
        :class="{ isDisabled: !selectedFilters[1] }"
        class="mb-1"
      >
        <app-collapse-item :title="$t('Vehicle counter')">
          <template slot="header">
            <div class="d-flex justify-content-between w-100">
              <span class="font-weight-bold ml-1">{{
                $t('Vehicle counter')
              }}</span>
              <div style="float: right">
                {{ locations(2).length }} / {{ allLocationsCount(2) }}
              </div>
            </div>
          </template>
          <location-list
            @zoomIn="zoomIn"
            :locations="locations(2)"
          ></location-list>
        </app-collapse-item>
      </app-collapse>
      <app-collapse
        v-if="!isLocalLayout()"
        :class="{ isDisabled: !selectedFilters[2] }"
        class="mb-1"
      >
        <app-collapse-item :title="$t('Cyclist counter')">
          <template slot="header">
            <div class="d-flex justify-content-between w-100">
              <span class="font-weight-bold ml-1">{{
                $t('Cyclist counter')
              }}</span>
              <div style="float: right">
                {{ locations(3).length }} / {{ allLocationsCount(3) }}
              </div>
            </div>
          </template>
          <location-list
            @zoomIn="zoomIn"
            :locations="locations(3)"
          ></location-list>
        </app-collapse-item>
      </app-collapse>
      <app-collapse :class="{ isDisabled: !selectedFilters[3] }">
        <app-collapse-item :title="$t('radars')" :isVisible="isLocalLayout()">
          <template slot="header">
            <div class="d-flex justify-content-between w-100">
              <span class="font-weight-bold ml-1">{{ $t('radars') }}</span>
              <div style="float: right">
                {{ locations(4).length }} / {{ allLocationsCount(4) }}
              </div>
            </div>
          </template>
          <b-button
            :disabled="!listChanged()"
            variant="primary"
            class="mt-1 ml-1 mb-1"
            @click="updateImak(imakLocations)"
          >
            {{ $t('enforce') }}</b-button
          >
          <location-list @zoomIn="zoomIn" :locations="locations(4)">
            <template slot="list-item-additional-content" slot-scope="data">
              <b-form-checkbox
                class="float-right pointer mt-1 mr-0"
                name="is-rtl"
                inline
                value="1"
                unchecked-value="0"
                @change="changeImakStatus(data.location.id)"
                :checked="getImakStatus(data.location.id)"
              />
            </template>
          </location-list>
        </app-collapse-item>
      </app-collapse>
    </b-col>
    <b-col md="10">
      <mymap ref="myMap" :locations="locations(null)"></mymap>
    </b-col>
  </b-row>
</template>

<script>
import Map from './components/Map.vue';
import LocationList from './components/LocationList.vue';
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
} from '@vue/composition-api';
import axios from '@/libs/axios';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import i18n from '@/libs/i18n';
import store from '@/store';
import { getLocationStatus } from '@/utils';
import { useImak } from '@/utils/imak';
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BCardText,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    mymap: Map,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardText,
    LocationList,
    BButton,
    BFormCheckbox,
  },
  setup(props, context) {
    const serachString = ref('');
    const filters = ref([
      { type: 1, image: require('@/assets/images/icons/speeding.svg') },
      { type: 2, image: require('@/assets/images/icons/car-counter.svg') },
      { type: 3, image: require('@/assets/images/icons/cycle-counter.svg') },
      { type: 4, image: require('@/assets/images/icons/radar.svg') },
    ]);

    const selectedFilters = ref([true, true, true, true]);
    const company = ref({});
    const availableFilters = ref([
      company.value.speedingModule,
      company.value.cyclistsModule,
      company.value.vehiclesModule,
      company.value.radarModule,
    ]);

    const deviceStates = ref([true, true, true, true, true]);

    const locationData = ref([]);
    const {
      updateImak,
      changeImakStatus,
      getImakStatus,
      imakLocations,
      imakLocationsOrg,
      listChanged,
      updateImakData,
    } = useImak();

    const changeFilter = filter => {
      if (availableFilters.value[filter - 1]) {
        selectedFilters.value[filter - 1] = !selectedFilters.value[filter - 1];
        selectedFilters.value = [...selectedFilters.value];
      }
    };

    const isRadarModuleActivated = computed(() => {
      return company.value.radarModule;
    });

    async function fetchData() {
      const res = await axios.get(
        'devices/withalarms/' + store.state.auth.userData.userId,
      );
      locationData.value = res.data;

      if (!listChanged()) {
        updateImakData(locationData.value);
      }
    }

    context.root.$eventBus.$on('alarm-triggered', alarm => {
      fetchData();
    });

    let refreshInterval;
    onMounted(async () => {
      const response = await store.dispatch(
        'companies/GET_COMPANY',
        store.state.auth.userData.companyId,
      );
      company.value = response.data;
      availableFilters.value = [
        company.value.speedingModule,
        company.value.vehiclesModule,
        company.value.cyclistsModule,
        company.value.radarModule,
      ];
      selectedFilters.value = [...availableFilters.value];
      if (store.state.appConfig.layout.isLocalLayout)
        selectedFilters.value = [false, false, false, true];

      fetchData();
      refreshInterval = setInterval(() => {
        fetchData();
      }, 15000);
    });

    onBeforeUnmount(() => {
      clearInterval(refreshInterval);
    });

    const locationFilter = location => {
      const query = serachString.value.toLowerCase();
      return (
        selectedFilters.value[location.type - 1] &&
        (location.name.toLowerCase().includes(query) ||
          location.location.toLowerCase().includes(query)) &&
        deviceStates.value[getLocationStatus(location) - 1]
      );
    };

    const locations = type => {
      return locationData.value.filter(location => {
        if (type != null)
          return locationFilter(location) && location.type == type;
        else
          return (
            locationFilter(location) &&
            location.latitude != null &&
            location.longitude
          );
      });
    };

    const numberOfDevices = status => {
      return locationData.value.filter(
        location =>
          locationFilter(location) && getLocationStatus(location) == status,
      ).length;
    };

    const isLocalLayout = () => {
      return store.state.appConfig.layout.isLocalLayout;
    };

    const allLocationsCount = type => {
      return locationData.value.filter(location => location.type == type)
        .length;
    };

    const titles = [
      i18n.t('Speed monitor'),
      i18n.t('Vehicle counter'),
      i18n.t('Cyclist counter'),
      i18n.t('radars'),
    ];

    const getTitle = type => {
      return titles[type - 1];
    };

    const myMap = ref(null);

    const zoomIn = location => {
      myMap.value.markerClicked(location);
    };

    const changeDeviceStateFilter = index => {
      deviceStates.value[index] = !deviceStates.value[index];
      deviceStates.value = [...deviceStates.value];
    };

    return {
      updateImak,
      isRadarModuleActivated,
      numberOfDevices,
      changeDeviceStateFilter,
      deviceStates,
      myMap,
      getTitle,
      locations,
      serachString,
      filters,
      changeFilter,
      selectedFilters,
      zoomIn,
      availableFilters,
      company,
      allLocationsCount,
      isLocalLayout,
      locationData,
      imakLocations,
      changeImakStatus,
      getImakStatus,
      listChanged,
    };
  },
};
</script>

<style scoped>
.isDisabled {
  opacity: 0.5;
}
.pointer {
  cursor: pointer;
}
#location-filters > .card-body {
  padding-bottom: 10px;
}
.imgIcon {
  width: 65px;
}
@media only screen and (min-width: 1954px) and (max-width: 2510px) {
  .imgIcon {
    margin: 0 15px;
  }
}
.roundbutton {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.button-green {
  background-color: #4caf50;
}
.button-red {
  background-color: red;
}
.button-yellow {
  background-color: #ffea00;
}
.button-orange {
  background-color: orange;
}
.button-blue {
  background-color: #0391e5;
}
.opacity {
  opacity: 0.5;
}
</style>
