import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import { useToast } from 'vue-toastification/composition';
import {
    ref,
} from '@vue/composition-api';

export const useImak = () => {
    const toast = useToast();

    const imakLocations = ref([]);
    const imakLocationsOrg = ref([]);

    const changeImakStatus = (locationId) => {
        const location = imakLocations.value.find((l) => l.id == locationId);
        location.imakEnabled = location.imakEnabled ? 0 : 1;
    };

    const getImakStatus = (locationId) => {
        const location = imakLocations.value.find((l) => l.id == locationId);
        return location.imakEnabled;
    };

    const updateImakData = (locationData) => {

        imakLocations.value = JSON.parse(
            JSON.stringify(locationData.filter((l) => l.type == 4)),
        );
        imakLocationsOrg.value = JSON.parse(
            JSON.stringify(locationData.filter((l) => l.type == 4)),
        );
    };

    const updateImak = async (locationData) => {
        const ids = locationData.map(d => {
            return {
                id: d.id,
                imakEnabled: d.imakEnabled,
            };
        });

        try {
            await store.dispatch('devices/IMAK_UPDATE', {
                devices: ids,
            });
            updateImakData(locationData);

            successToast(
                toast,
                'Pobiranje iz imak naprav uspešno posodobljeno.',
            );
        } catch (e) {
            dangerToast(toast, e.message);
        }
    }

    const listChanged = () => {
        return (
            JSON.stringify(imakLocations.value) !=
            JSON.stringify(imakLocationsOrg.value)
        );
    };

    return { updateImak, changeImakStatus, getImakStatus, imakLocations, imakLocationsOrg, listChanged, updateImakData }
}